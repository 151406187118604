<template>
  <iq-card>
    <template v:slot="body">
      <!-- 捐款金额 -->
      <div class="common-part amount-box">
        <div>
          <h3>Select an amount</h3>
          <ul class="btn-select m-0 p-0 three-btn">
            <li
              class="self-r"
              :class="{ outlineBlue: activeLi == 1 }"
              @click="activeLi = 1"
            >
              <div>$100 </div>
              <div>Foundation Donor</div>
              <input
                type="radio"
                v-model="amount"
                name="some-radios"
                value="100"
                class="cursor-pointer"
              />
            </li>
            <li
              class="self-r"
              :class="{ outlineBlue: activeLi == 2 }"
              @click="activeLi = 2"
            >
              <div>$500</div>
              <div>John Armbruster Donor</div>
              <input
                type="radio"
                v-model="amount"
                name="some-radios"
                value="500"
                class="cursor-pointer"
              />
            </li>
            <li
              class="self-r"
              :class="{ outlineBlue: activeLi == 3 }"
              @click="activeLi = 3"
            >
              <div>$1000</div>
              <div>John Armbruster Donor</div>
              <input
                type="radio"
                v-model="amount"
                name="some-radios"
                value="1000"
                class="cursor-pointer"
              />
            </li>
            <li
              class="self-r"
              :class="{ outlineBlue: activeLi == 4 }"
              @click="activeLi = 4"
            >
              <div>$5000 </div>
              <div>Phil Pugsley Patron</div>
              <input
                type="radio"
                v-model="amount"
                name="some-radios"
                value="5000"
                class="cursor-pointer"
              />
            </li>
            <li
              class="self-r"
              :class="{ outlineBlue: activeLi == 5 }"
              @click="activeLi = 5"
            >
              <div>$10000</div>
              <div>Henry Giessenbier Fellow</div>
              <input
                type="radio"
                v-model="amount"
                name="some-radios"
                value="10000"
                class="cursor-pointer"
              />
            </li>
            <li
              class="self-r"
              :class="{ outlineBlue: activeLi == 6 }"
              @click="activeLi = 6"
            >
              <div>$20000</div>
              <div>David Chua-Unsu Patron</div>
              <input
                type="radio"
                v-model="amount"
                name="some-radios"
                value="20000"
                class="cursor-pointer"
              />
            </li>
            <li :class="{ outlineBlue: activeLi == 7 }" @click="activeLi = 7">
              Other
              <input
                type="radio"
                v-model="amount"
                name="some-radios"
                value=""
                class="cursor-pointer"
              />
            </li>
          </ul>
        </div>
        <div v-if="activeLi == 7" class="d-flex align-item-center self_pre_input mt-4">
          <el-input
            v-model="amount"
            class="radiusInt"
            @input="
              value => {
                limitInput(value);
              }
            "
            :disabled="activeLi != 7"
          >
          <i slot="prefix" class="el-input__icon">$</i>
          <template slot="append" v-if="amount > 0">
            <div style="text-align: center">
              <span v-if="amount > 0&&amount<500">Foundation Donor</span>
              <span v-if="amount >= 500&&amount<5000">John Armbruster Donor</span>
              <span v-if="amount >= 5000&&amount<10000">Phil Pugsley Patron</span>
              <span v-if="amount >= 10000&&amount<20000">Henry Giessenbier Fellow</span>
              <span v-if="amount >= 20000">David Chua-Unsu Patron</span>
            </div>
          </template>
          </el-input>
        </div>
      </div>
    </template>
  </iq-card>
</template>

<script>
export default {
  data() {
    return {
      amount: "",
      activeLi: 0
    };
  },

  components: {},

  created() {},
  computed: {},
  watch: {
    amount() {
      this.$emit("getAmountFunc", this.amount);
      this.$bus.$emit("getAmountDon", this.amount);
    }
  },
  methods: {
    //限制金额
    limitInput(value, item) {
      if (/[^0-9.]/g.test(value)) {
        this.$message.warning("Only numbers can be entered");
      }
      this.amount = value.replace(/[^0-9.]/g, "");
    }
  }
};
</script>
<style lang="scss" scoped>
  .outlineBlue {
    color: #50b5ff;
    border: 1px solid #50b5ff !important;
    background-color: #f5fbff;
  }

  .self_pre_input {
    ::v-deep .form-control {
      border-radius: 0;
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;
    }
    ::v-deep .el-input-group__append{
      width:200px;
    }
    ::v-deep .el-input__icon {
      color: #606266;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
    ::v-deep .el-input__inner {
      padding-left: 15px;
      height: 45px;
      line-height: 45px;
    }
  }

  @media (min-width: 768px) {
    .common-part {
      padding: 20px;

      >h3 {
        font-size: 16px;
        color: #333;
        font-weight: 500;
        margin-bottom: 26px;
      }

      .btn-select {
        display: flex;
        flex-wrap: wrap;
        margin-top: 26px;

        .outlineBule {
          border-color: #50b5ff;
          background-color: #50b5ff;
        }

        >li {
          width: 190px;
          height: 70px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: 1px solid #f1f1f1;
          border-radius: 10px;
          text-align: center;
          line-height: 30px;
          position: relative;
          cursor: pointer;
          margin: 10px;

          >input {
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      .choosed-result {
        padding-top: 30px;
      }
    }
  }

  @media (max-width: 768px) {
    .common-part {
      padding: 20px;

      >h3 {
        font-size: 16px;
        color: #333;
        font-weight: 500;
        margin-bottom: 26px;
      }

      .btn-select {
        display: flex;
        flex-wrap: wrap;
        margin-top: 26px;

        >li {
          width: 100%;
          height: 70px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: 1px solid #f1f1f1;
          border-radius: 10px;
          text-align: center;
          line-height: 30px;
          position: relative;
          margin: 10px;

          >input {
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      >.three-btn {
        display: flex;
        flex-wrap: wrap;

        >li {
          width: calc(33.3333% - 20px);
        }
      }

      .choosed-result {
        padding-top: 30px;
      }
    }
  }
</style>