<template>
  <iq-card>
    <template v:slot="body">
      <div class="common-part">
        <form class="needs-validation" novalidate action="#">
          <div class="form-row">
            <div class="col-md-12 mb-2 d-flex align-items-center">
              <el-checkbox v-model="serve_checked"></el-checkbox>
              <label>I agree to the <a class="cursor-pointer" @click="$router.push(`/PrivacyAndPolicy?id=3`)">Terms of
                  Service</a> and <a class="cursor-pointer" @click="$router.push(`/PrivacyAndPolicy?id=2`)">Privacy
                  Policy</a>
              </label>
            </div>
            <!-- <div class="col-md-12 mb-3">
              <el-checkbox v-model="checked"></el-checkbox>
              <label for="termsAndConditions">I'd like to cover all transaction fees so 100% of my donation
                goes to JCI Foundation Inc.</label>
            </div> -->
            <div class="d-flex align-items-center">
              <b-button style="width: 140px; height: 45px" class="mr-4" @click="createOrder" variant="primary"
                :disabled="!serve_checked||!checked">Give Now
              </b-button>
              <span style="font-size: 16px">Amount:
                <b style="color: #333; font-size: 20px">${{totalAmount}}</b> USD</span>
            </div>
          </div>
        </form>
      </div>
    </template>
  </iq-card>
</template>

<script>
  export default {
    data() {
      return {
        checked: true,
        serve_checked: "",
        amount: 0,
        commission: 0
      };
    },

    components: {},

    computed: {
      totalAmount() {
        if (this.checked) {
          return (Number(this.amount) + Number(this.amount * this.commission / 100)).toFixed(2)
        } else {
          return Number(this.amount).toFixed(2)
        }
      }
    },

    created() {
      this.$bus.$on('getAmountDon', (val) => {
        this.amount = val || 0
      })
      this._getDonationCommission()
    },

    beforeDestroy() {
      this.$bus.$off('getAmountDon')
    },

    methods: {
      createOrder() {
        if (!this.serve_checked) return this.$message.warning('Please check the terms !')
        this.$emit("donate", this.totalAmount);
      },

      // 获取捐赠手续费 
      async _getDonationCommission() {
        let res = await this.$http.getDonationCommission()
        if (res.status == 200) this.commission = res.data
      }
    },
  };
</script>
<style lang="scss" scoped>
  .common-part {
    padding: 20px;

    >h3 {
      font-size: 16px;
      color: #333;
      font-weight: 500;
      margin-bottom: 26px;
    }
  }

  ::v-deep .el-checkbox {
    margin-right: 10px !important;
  }
</style>