<template>
  <div class="container690">
    <SelectAmount @getAmountFunc="getAmount" />
    <!-- 添加订阅 -->
    <SubscribeForm @getSubscribe="getSubscribe" />
    <InformationForm ref="inForm" @getInform="getInform" />
    <Pay @donate="donate" />
  </div>
</template>

<script>
  import SelectAmount from "./components/SelectAmountF";
  import InformationForm from "./components/InformationForm";
  import SubscribeForm from "./components/SubscribeForm";
  import Pay from "./components/Pay";
  export default {
    data() {
      return {
        amount: "",
        donated_type: 2,
        form: {},
        subscribe: {},
      };
    },

    components: { SelectAmount, InformationForm, Pay, SubscribeForm },
    methods: {
      getInform(form) {
        this.form = form
      },

      getAmount(val) {
        this.amount = val;
      },

      getSubscribe(form) {
        this.subscribe = form
      },

      donate(amount) {
        if (!this.amount) return this.$message.warning('Please select or fill in the amount')
        if (this.subscribe.is_subscribe == 2) {
          this.subscribeDonate(amount)
        } else {
          this.commonDonate(amount)
        }
      },

      // 普通捐赠
      commonDonate(amount) {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.$http.donate({
          user_id: userInfo.user_id,
          donated_type: this.donated_type,
          donation_amount: this.amount,
          amount,
          donor_organization_id: "",
          donor_first_name: this.form.first_name,
          donor_last_name: this.form.last_name,
          donor_email: this.form.email,
          donor_country_id: this.form.country_id,
          donor_address: this.form.address,
          donor_telephone: this.form.tel,
          donor_company: this.form.company,
          donor_fax: this.form.fax,
          // donor_organization_id: userInfo.organization_id,
          // is_pay_all: 1,
        })
          .then((res) => {
            if (res.status == 200) {
              if (res.data.is_jump == 1) {
                this.$router.push({
                  path: `/PayMoney`,
                  query: {
                    model_type: 8,
                    id: res.data.id,
                  },
                });
              }
            }
          });
      },

      // 订阅捐赠
      subscribeDonate(amount) {
        let data = {
          donation_amount: this.amount,
          amount,
          donor_country_id: this.form.country_id,
          donor_address: this.form.address,
          donor_telephone: this.form.tel,
          donor_company: this.form.company,
          donor_fax: this.form.fax,
          email: this.form.email,
          name: this.form.first_name + ' ' + this.form.last_name,
          end_date: this.subscribe.end_date,
          start_date: this.subscribe.start_date,
          cycles: this.subscribe.is_end_date ? this.subscribe.cycles : '',
          is_end_date: this.subscribe.is_end_date,
        }
        sessionStorage.setItem('subscribe_info', JSON.stringify(data))
        this.$router.push({
          name: 'PayMoney',
          path: `/PayMoney`,
          query: {
            is_subscribe: 1
          },
        });
      }
    },
  };
</script>
<style lang="scss" scoped>
  .appendAsterisk::after {
    content: "*";
    color: red;
    display: inline;
    margin-left: 5px;
  }

  .self_pre_input {
    >span {
      display: block;
      width: 120px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      background: #f8f8f8;
      color: #999999;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    >input#selfInput {
      border-radius: 0;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-color: #f1f1f1;
    }
  }
</style>