<template>
  <iq-card>
    <template v:slot="body">
      <div class="common-part">
        <h3>Payment Type</h3>
        <section>

          <form>
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <el-radio-group v-model="form.is_subscribe" v-removeAriaHidden @input="getForm">
                  <el-radio :label=1>One-off</el-radio>
                  <el-radio :label=2>Subscription</el-radio>
                </el-radio-group>
              </div>
              <div class="col-md-12 mb-3" v-if="form.is_subscribe==2">
                <span>Interval: Monthly</span>
              </div>
              <div class="col-md-12 mb-3" v-if="form.is_subscribe==2">
                <span>Start Date: {{(new Date().toISOString().slice(0, 10))|formatDateSub}}</span>
              </div>
              <div class="col-md-12 mb-3 d-flex align-items-center" v-if="form.is_subscribe==2">
                <label for="validationCustomUsername" class="appendAsterisk d-flex align-items-center">
                  <el-switch v-model="form.is_end_date" :active-value="1" :inactive-value="0"></el-switch>
                  <span class="ml-1" style="white-space: nowrap;">Has End Date </span>
                </label>
                <div class="ml-1 d-flex align-items-center" v-if="form.is_end_date" style="max-width: 180px;">
                  <el-select v-model="form.cycles" @change="changeNumber">
                    <el-option v-for="item in options" :key="item" :label="item" :value="item">
                    </el-option>
                  </el-select>
                  <span class="ml-2" style="white-space: nowrap;">{{form.end_date|formatDateSub}}</span>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </template>
  </iq-card>
</template>

<script>
  import moment from "moment";
  export default {

    data() {
      return {
        form: {
          is_subscribe: 1,
          start_date: new Date().toISOString().slice(0, 10),
          end_date: '',
          is_end_date: 0,
          cycles: 1,
        },
        options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      };
    },


    created() {
      this.getForm()
      this.changeNumber(1)
    },

    methods: {
      getForm() {
        this.$emit("getSubscribe", this.form);
      },

      changeNumber(quantity) {
        let date = new Date()
        const currentDate = new Date();
        const newMonth = currentDate.getMonth() + quantity;
        const lastDayOfMonth = new Date(currentDate.getFullYear(), newMonth + 1, 0).getDate();
        currentDate.setMonth(newMonth);
        currentDate.setDate(Math.min(currentDate.getDate(), lastDayOfMonth));
        this.form.end_date = moment(currentDate).format("YYYY-MM-DD")
      }
    },

  };
</script>
<style lang="scss" scoped>
  .common-part {
    padding: 20px;
    color: #333;

    >h3 {
      font-size: 16px;
      color: #333;
      font-weight: 500;
      margin-bottom: 26px;
    }
  }

  ::v-deep .el-date-editor--date .el-input__inner,
  ::v-deep .el-select .el-input__inner {
    height: 45px;
    border-radius: 10px;
  }
</style>